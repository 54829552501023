import UnitCard from '$/components/Organisms/UnitCard';
import useFeatureFlag from '$/hooks/useFeatureFlag';
import { memo } from 'react';

import useCarouselConfig from './logic';
import * as Styled from './styles';
import type { Props } from './types';

function RecommendedUnits({
  className,
  onContact,
  tag,
  units,
  handleSeeAll,
}: Props): JSX.Element {
  const { configurableProps, isTablet } = useCarouselConfig();
  const hideUpgradePricingEnabled = useFeatureFlag('HIDE_UPGRADE_PRICING');

  const unitsArr = units
    .slice(0, 3)
    .map((unit) => (
      <UnitCard
        key={unit.id}
        onClickContact={onContact}
        tag={tag}
        unit={unit}
        url={`/upgrade-place/${unit.id}`}
        hideUpgradePricingEnabled={hideUpgradePricingEnabled}
      />
    ));

  if (isTablet)
    return <Styled.Grid className={className}>{unitsArr}</Styled.Grid>;

  if (unitsArr.length <= 1) {
    return (
      <Styled.Container className={className}>{unitsArr}</Styled.Container>
    );
  }

  return (
    <Styled.Container className={className}>
      <Styled.DefaultCarousel {...configurableProps()}>
        {unitsArr}
      </Styled.DefaultCarousel>
      <Styled.AllButton
        label="See all"
        onClick={handleSeeAll}
        variant="secondary"
        $hide={units.length < 3}
      />
    </Styled.Container>
  );
}

export default memo(RecommendedUnits);
